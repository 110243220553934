import { ErrorHandler, Input, OnChanges, OnInit } from '@angular/core';
import { DetailBase } from '~/app/open-age/core/structures';
import { Log } from '../models';
import { LogService } from '../services/log.service';

export abstract class LogDetailBaseComponent extends DetailBase<Log> implements OnInit, OnChanges {

  constructor(
    api: LogService,
    private errorHandler: ErrorHandler,
  ) {
    super({ api, errorHandler });
  }

  @Input()
  code: string;

  ngOnInit() {
    
  }

  ngOnChanges() {
    if (this.code) {
      this.get(this.code, true)
    }
  }
}
