import { Component, ErrorHandler, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { RoleService } from 'src/app/open-age/core/services';
import { DocsService } from 'src/app/open-age/drive/services';
import { Doc } from '~/app/open-age/drive/models';
import { DisplayImageDialogComponent } from '~/app/shared/components/display-image-dialog/display-image-dialog.component';
import { UxService } from '../../../core/services/ux.service';
import { ValidationService } from '../../../core/services/validation.service';
import { FileListBaseComponent } from '../../../open-age/drive/components/file-list-base.component';
import { FileUploaderDialogComponent } from '../file-uploader-dialog/file-uploader-dialog.component';
import { PageOptions } from '~/app/open-age/core/models';
import { ConfirmDialogComponent } from '~/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EncryptionDecryptionService } from '~/app/services/encryptDecrypt/encryption-decryption.service';


@Component({
  selector: 'drive-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent extends FileListBaseComponent {

  constructor(
    auth: RoleService,
    fileService: DocsService,
    private uxService: UxService,
    public dialog: MatDialog,
    validator: ValidationService,
    encryptDecryptService: EncryptionDecryptionService
  ) {
    super(auth, fileService, uxService, validator, encryptDecryptService);
  }

  getFileIcon(mineType: string) {
    let icon = 'si-myExcelFile';

    switch (mineType) {
      case 'application/msword':
        icon = 'si-docFile';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon = 'si-myExcelFile';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = 'si-docFile';
        break;
      case 'application/vnd.ms-excel':
        icon = 'si-myExcelFile';
        break;
      case 'application/pdf':
        icon = 'si-pdfFile';
        break;
      case 'video/mp4':
        icon = 'si-videoFile';
        break;
      default:
        icon = 'si-docFile';
    }
    return icon;
  }

  getFileSize(file) {
    if (!file.size) {
      return '--';
    }
    const fileSize: number = Number((file.size / (1024 * 1024)).toFixed(2));
    return `${fileSize} MB`;
  }

  openDialog(doc?: Doc, isNew?: boolean): void {
    const instance = this.uxService.openDialog(FileUploaderDialogComponent)
    instance.componentInstance.entity = this.entity;
    instance.componentInstance.folder = this.folder;
    if (doc) {
      instance.componentInstance.doc = new Doc(doc);
    } else {
      instance.componentInstance.orderNo = this.getMaximum() + 1;
    }
    if (isNew) {
      instance.componentInstance.isNew = isNew;
    }
    instance.afterClosed().subscribe(() => {
      this.fetch()
    });
  }

  showImgInDialog(doc: Doc): void {
    if (this.readonly) {
      return;
    } else {
      const dialogRef = this.uxService.openDialog(DisplayImageDialogComponent, {
        panelClass: 'showImageDialog',
        width: 'auto',
        height: 'auto'
      });

      const instance = dialogRef.componentInstance;
      instance.doc = doc;
    }
  }

  confirmDialog(id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.remove(id);
        return this.uxService.showInfo('Deleted sucessfully');
      }
    });
  }
}
