import { UxService } from './../../../core/services/ux.service';
import { TelepainApiBase } from './telepain-api.base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleService } from '../../core/services/role.service';
import { Patient } from '../models/patient';
import { HealthData } from '../models/patient-health-data';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends TelepainApiBase<Patient>{

  health: TelepainApiBase<any>;
  assignSurveys: TelepainApiBase<any>;
  curesEntity: TelepainApiBase<any>;

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('v1/patients', http, roleService, uxService);
    this.health = new TelepainApiBase<HealthData>(`v1/patients/health-records`, http, roleService, uxService);
    this.assignSurveys = new TelepainApiBase<HealthData>(`v1/patients/assign-new-surveys`, http, roleService, uxService);
    this.curesEntity = new TelepainApiBase<HealthData>(`v1/patients/cures-entity`, http, roleService, uxService);
  }
}
