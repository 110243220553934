import { Component } from '@angular/core';
import { LogListBaseComponent } from '~/app/open-age/insight/components/log-list.base.component';
import { LogService } from '~/app/open-age/insight/services/log.service';
import { UxService } from '../../../core/services/ux.service';
import { EncryptionDecryptionService } from '~/app/services/encryptDecrypt/encryption-decryption.service';

@Component({
  selector: 'insight-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.css']
})
export class LogListComponent extends LogListBaseComponent {

  tableColumns = ['level', 'location', 'message', 'error', 'user', 'action']

  constructor(
    service: LogService,
    uxService: UxService,
    encryptDecryptService: EncryptionDecryptionService
  ) {
    super(service, uxService, uxService, encryptDecryptService);
  }

  ngOnInit() {
    this.fetch({isDecrypt: true, limit: 10});
  }

}
