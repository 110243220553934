import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PagerModel } from '~/app/open-age/core/structures';
import { UxService } from '~/app/core/services';
import { Entity, Journal, User } from '../models';
import { JournalService } from '../services/journal.service';
import { EncryptionDecryptionService } from '../../../services/encryptDecrypt/encryption-decryption.service';

export class JournalListBaseComponent extends PagerModel<Journal> implements OnInit, OnChanges {

  @Input()
  user: string | User;

  @Input()
  entity: Entity;

  @Input()
  name: string;

  constructor(
    private api: JournalService,
    public uxService: UxService,
    errorHandler: ErrorHandler,
    private encryptionService: EncryptionDecryptionService
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'user', 'entity-id', 'entity-type', 'entity-name'
      ], pageOptions: {
        limit: 10,
        isDecrypt: true
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.refresh();
  }

  async refresh() {
    this.filters.reset(false);

    if (this.user) {
      this.filters.set('user', this.user);
    }

    if (this.entity) {
      this.filters.set('entity-id', this.entity.id || '');
      this.filters.set('entity-type', this.entity.type || '');
    }

    if (this.name) {
      const name = await this.encryptionService.encrypt(this.name);
      this.filters.set('entity-name', name);
    }

    this.fetch({isDecrypt: true, limit: 10});
  }

}

