export const environment = {
  // enviroment = tenant
  production: false,
  silent: false,

  // name: 'devv3',
  // name: 'uat',
  name: 'qa',
  //  name: 'Pre-Prod',
  // name: 'sandbox',
  // name: 'prod',

  code: null,
  // host: 'uat.telepain.md',
  // host: 'devv3.telepain.md',
  // host: 'uat.telepain.md',
  host: 'qa.telepain.md',
  // host: 'sandbox.telepain.md',

  // source: 'https://opos-api-devv3.telepain.md/directory/api/tenants',
  source: 'https://opos-api-qa.telepain.md/directory/api/tenants',
  // source: 'https://opos-api-uat.telepain.md/directory/api/tenants',
  // source: 'https://opos-api-preprod.telepain.md/directory/api/tenants',
  // source: 'https://stage.openage.in/directory/api/tenants',
  // source: 'https://opos-api-sandbox.telepain.md/directory/api/tenants',
  // source: 'https://api.telepain.md/directory/api/tenants',

  organization: { code: null },
  loginTypes: ['email'],
  cacheDuration: 0, // minutes

  services: [],

  // if services section exists then it would be used

 /*  "services": [
    {
      "code": "telepain",
      // "url": "http://localhost:3077/api"
      "url": "https://opos-api-dev.telepain.md/telepain/api"
    },
    {
      "code": "directory",
      // "url": "http://localhost:3001/api"
      "url": "https://opos-api-dev.telepain.md/directory/api"
    },
    {
      "code": "bap",
      "url": "https://opos-api-dev.telepain.md/bap/api"
    },
    {
      "code": "welcome",
      // "url": "http://localhost:3005/api"
      "url": "https://opos-api-dev.telepain.md/welcome/api"
    },
    {
      "code": "sendIt",
      "url": "https://opos-api-dev.telepain.md/sendIt/api"
    },
    {
      "code": "drive",
      "url": "https://opos-api-dev.telepain.md/drive/api"
    },
    {
      "code": "insight",
      // "url": "http://localhost:3043/api"
      "url": "https://opos-api-dev.telepain.md/insight/api"
    },
    {
      "code": "gateway",
      "url": "https://opos-api-dev.telepain.md/gateway/api"
    }
  ],
 */
  // "services": [
  //   {
  //     "code": "telepain",
  //     // "url": "http://localhost:3077/api"
  //     "url": "https://opos-api-uat.telepain.md/telepain/api"
  //   },
  //   {
  //     "code": "directory",
  //     // "url": "http://localhost:3001/api"
  //     "url": "https://opos-api-uat.telepain.md/directory/api"
  //   },
  //   {
  //     "code": "bap",
  //     "url": "https://opos-api-uat.telepain.md/bap/api"
  //   },
  //   {
  //     "code": "welcome",
  //     // "url": "http://localhost:3005/api"
  //     "url": "https://opos-api-uat.telepain.md/welcome/api"
  //   },
  //   {
  //     "code": "sendIt",
  //     "url": "https://opos-api-uat.telepain.md/sendIt/api"
  //   },
  //   {
  //     "code": "drive",
  //     "url": "https://opos-api-uat.telepain.md/drive/api"
  //   },
  //   {
  //     "code": "insight",
  //     "url": "https://opos-api-uat.telepain.md/insight/api"
  //   },
  //   {
  //     "code": "gateway",
  //     "url": "https://opos-api-uat.telepain.md/gateway/api"
  //   }
  // ],

  // "services": [
  //   {
  //     "code": "telepain",
  //     // "url": "http://localhost:3077/api"
  //     "url": "https://opos-api-qa.telepain.md/telepain/api"
  //   },
  //   {
  //     "code": "directory",
  //     // "url": "http://localhost:3001/api"
  //     "url": "https://opos-api-qa.telepain.md/directory/api"
  //   },
  //   {
  //     "code": "bap",
  //     "url": "https://opos-api-qa.telepain.md/bap/api"
  //   },
  //   {
  //     "code": "welcome",
  //     "url": "http://localhost:3005/api"
  //     // "url": "https://opos-api-qa.telepain.md/welcome/api"
  //   },
  //   {
  //     "code": "sendIt",
  //     "url": "https://opos-api-qa.telepain.md/sendIt/api"
  //   },
  //   {
  //     "code": "drive",
  //     "url": "https://opos-api-qa.telepain.md/drive/api"
  //   },
  //   {
  //     "code": "insight",
  //     "url": "https://opos-api-qa.telepain.md/insight/api"
  //   },
  //   {
  //     "code": "gateway",
  //     "url": "https://opos-api-qa.telepain.md/gateway/api"
  //   }
  // ],

  // if navs section exists then it would be used
  navs: []
};
