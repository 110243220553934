import { ErrorHandler, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IApi } from '~/app/open-age/core/services/api.interface';
import { DetailOptions } from './detail-options.model';

export class DetailBase<TModel> {

  private originalModel: TModel;
  @Input()
  properties: TModel;

  @Output()
  fetched: EventEmitter<TModel> = new EventEmitter();

  @Output()
  created: EventEmitter<TModel> = new EventEmitter();

  @Output()
  updated: EventEmitter<TModel> = new EventEmitter();

  @Output()
  removed: EventEmitter<TModel> = new EventEmitter();

  @Output()
  errored: EventEmitter<any> = new EventEmitter();

  errors: string[] = [];
  code: number | string;
  isProcessing = false;

  private options: DetailOptions<TModel>;

  constructor(options: {
    api: IApi<TModel>,
    cache?: IApi<TModel>,
    properties?: TModel,
    watch?: number,
    map?: (obj: any) => TModel,
    fields?: {
      id: 'id' | string,
      timeStamp: 'timeStamp' | string
    },
    errorHandler?: ErrorHandler
  } | DetailOptions<TModel>) {

    if (options instanceof DetailOptions) {
      this.options = options;
    } else {
      this.options = new DetailOptions(options);

    }

    if (this.options.properties) {
      this.originalModel = JSON.parse(JSON.stringify(options.properties));
      this.setModel(options.properties);
    }
  }

  private setModel(model: TModel): void {
    this.properties = model;
    this.code = this.options.fields ? model[this.options.fields.id] : model['id'];
    if (this.errors) {
      this.errors.splice(0, this.errors.length);
    }
  }

  get(id: string | number, isDecrypt?: boolean): Observable<TModel> {
    this.isProcessing = true;
    const subject = new Subject<TModel>();
    this.options.api.get(id, {
      watch: this.options.watch,
      isDecrypt: isDecrypt,
      map: this.options.map,
    }).subscribe((data) => {
      this.setModel(data);
      if (this.options.cache) {
        this.options.cache.update(id, data).subscribe();
      }
      this.isProcessing = false;
      subject.next(this.properties);
      this.fetched.emit(this.properties);
      return data;
    }, (error) => {
      this.isProcessing = false;
      this.errors = [error];
      this.errored.next(error);
      if (this.options.errorHandler) {
        this.options.errorHandler.handleError(error.message || error);
      }
      subject.error(error);
    });
    return subject.asObservable();
  }

  set(data: TModel) {
    this.setModel(data);
  }

  refresh() {
    return this.get(this.code);
  }

  clear() {
    this.setModel(JSON.parse(JSON.stringify(this.options.properties)));
  }

  reset() {
    this.setModel(this.originalModel);
  }

  save(model?: any): Observable<TModel> {
    if (this.properties[this.options.fields.id]) {
      return this.update(model);
    } else {
      return this.create(model);
    }
  }

  create(model?: any): Observable<TModel> {
    this.isProcessing = true;
    model = model || this.properties;
    const subject = new Subject<TModel>();
    this.options.api.create(model).subscribe((data) => {
      this.setModel(data);
      if (this.options.cache && this.options.fields.id) {
        this.options.cache.update(data[this.options.fields.id], data).subscribe();
      }
      this.isProcessing = false;
      this.created.emit(this.properties);
      subject.next(this.properties);
      return data;

    }, (error) => {
      this.isProcessing = false;
      this.errors = [error];
      this.errored.next(error);
      if (this.options.errorHandler) {
        this.options.errorHandler.handleError(error.message || error);
      }
      subject.error(error);
    });
    return subject.asObservable();
  }

  update(model?: any): Observable<TModel> {
    this.isProcessing = true;
    const id = this.properties[this.options.fields.id];
    model = model || this.properties;
    const subject = new Subject<TModel>();
    this.options.api.update(id, model).subscribe((data) => {
      this.setModel(data);
      if (this.options.cache) {
        this.options.cache.update(this.code, data).subscribe();
      }
      this.isProcessing = false;
      this.updated.emit(this.properties);
      subject.next(this.properties);
      return data;
    }, (error) => {
      this.isProcessing = false;
      this.errors = [error];
      this.errored.next(error);
      if (this.options.errorHandler) {
        this.options.errorHandler.handleError(error.message || error);
      }
      subject.error(error);
    });
    return subject.asObservable();
  }

  remove(id): Observable<TModel> {
    if (!id) {
      const id = this.code;
    }
    this.isProcessing = true;
    const subject = new Subject<TModel>();

    this.options.api.remove(id).subscribe(() => {
      if (this.options.cache) {
        this.options.cache.remove(id).subscribe();
      }
      this.isProcessing = false;
      this.removed.emit(this.properties);
      subject.next(this.properties);
      return;
    }, (error) => {
      this.isProcessing = false;
      this.errors = [error];
      this.errored.next(error);
      if (this.options.errorHandler) {
        this.options.errorHandler.handleError(error.message || error);
      }
      subject.error(error);
    });
    return subject.asObservable();
  }
}
