import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PagerModel } from '~/app/open-age/core/structures';
import { UxService } from '~/app/core/services';
import { Log, User } from '../models';
import { LogService } from '../services/log.service';
import { EncryptionDecryptionService } from '~/app/services/encryptDecrypt/encryption-decryption.service';

export class LogListBaseComponent extends PagerModel<Log> implements OnInit, OnChanges {

  @Input()
  user: string | User;

  @Input()
  message: string;

  constructor(
    private api: LogService,
    public uxService: UxService,
    errorHandler: ErrorHandler,
    private encryptDecryptService: EncryptionDecryptionService
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'user', 'message'
      ], pageOptions: {
        limit: 10,
        isDecrypt: true
      }
    });
  }

  ngOnInit() {
    // this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  async refresh() {
    this.filters.reset(false);

    if (this.user) {
      this.filters.set('user', this.user);
    }

    if (this.message) {
      let message = await this.encryptDecryptService.encrypt(this.message);
      this.filters.set('message', message);
    }

    this.fetch();
  }

}
