import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { ValidationService } from '~/app/core/services/validation.service';
import { Organization, Division } from '~/app/open-age/directory/models';
import { OrganizationService, DivisionService } from '~/app/open-age/directory/services';
import { Link } from '~/app/open-age/core/structures';
import { NavService } from '~/app/core/services';
import { ActivatedRoute } from '@angular/router';
import { PracticeSurveyCategory } from '~/app/open-age/telepain/models/practice-survey-category';
import { PracticeService } from '~/app/open-age/telepain/services/practice.service';
import { PatientService } from '~/app/open-age/telepain/services/patient.service';
import { Patient } from '~/app/open-age/telepain/models/patient';
import { MatDialog } from '@angular/material';
import { SurveyCategory } from '~/app/open-age/telepain/models/survey-category';
import { SelectSurveyCategoryDialogComponent } from '../dialog/select-survey-category/select-survey-category.component';
import { timeZonesList } from '../../services/telepain/telepain.service';

@Component({
  selector: 'app-practice-view',
  templateUrl: './practice-view.component.html',
  styleUrls: ['./practice-view.component.css']
})
export class PracticeViewComponent implements OnInit {

  practice: Organization;
  divisions: Division[];
  isProcessing: boolean;
  id: string;
  isCurrent = true;
  page: Link;
  categories: PracticeSurveyCategory[]
  patientsList: Patient[]
  newPractice: Organization;
  timeZones: any;
  constructor(
    public dialog: MatDialog,
    private organizationService: OrganizationService,
    private errorHandler: UxService,
    private validator: ValidationService,
    private navService: NavService,
    private route: ActivatedRoute,
    private divisionService: DivisionService,
    private practiceService: PracticeService,
    private PatientService: PatientService
  ) {
  }

  ngOnInit() {
    this.timeZones = timeZonesList;
    this.page = this.navService.register(`/practice/list/view/:id`, this.route, (isCurrent, params) => {
      this.id = params.get("id")
      this.isCurrent = isCurrent;
      if (this.isCurrent) {
        this.setContext();
      }
    });
    if (this.id) {
      this.isProcessing = true
      this.organizationService.get(this.id,{isDecrypt: true}).subscribe((item) => {
        this.practice = new Organization(item)
        this.organizationService.get(this.practice.id,{isDecrypt: true}).subscribe((item) => {
          this.newPractice = item
        })
        this.practiceService.surveyCategory.search({ code: this.practice.code }).subscribe(page => {
          this.categories = page.items
        })
        this.practicePhoneNumberFormat(this.practice.phone)
        this.practiceFaxNumberFormat(this.practice.meta.fax)
        this.divisionService.search({ organization: item.id },{isDecrypt: true}).subscribe(page => {
          this.divisions = page.items
          let i = 0
          for (const division of this.divisions) {
            if (division.meta && division.meta.phone) {
              this.divisionPhoneNumberFormat(division.meta.phone, i)
              this.divisionFaxNumberFormat(division.meta.fax, i)
            }
            i++
          }
          this.isProcessing = false
        }, (error) => {
          this.errorHandler.handleError("Locations Not Found");
          this.isProcessing = false;
          this.back()
        })
      }, (error) => {
        this.errorHandler.handleError("Practice Not Found");
        this.isProcessing = false;
        this.back()
      })
    } else {
      this.errorHandler.handleError("Practice Id Required");
      this.back()
    }
  }

  update() {
    this.isProcessing = true
    this.updateDivisions().then(() => {
      this.organizationService.update(this.practice.id, this.practice,{isDecrypt: true}).subscribe((item) => {
        this.practice = item
        this.errorHandler.showInfo("Practice Updated")
        this.isProcessing = false
      })
    }).catch((err) => {
      this.isProcessing = false
      this.errorHandler.handleError(err.message || err)
    });
  }

  refreshCategories() {
    this.isProcessing = true
    this.practiceService.surveyCategory.search({ code: this.practice.code }).subscribe(page => {
      this.isProcessing = false
      this.categories = page.items
    }, err => {
      this.isProcessing = false
    })
  }

  addDivision() {
    this.divisions.push(new Division({ organization: this.practice }))
  }

  setContext() {
  }

  back() {
    this.navService.goto("/practice/list")
  }

  updateDivisions(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.divisions && this.divisions.length) {
        let i = this.divisions.length
        for (const division of this.divisions) {
          if (division.id) {
            this.divisionService.update(division.id, division,{isDecrypt: true})
            --i
          } else {
            division.name = division.address.city
            division.code = division.address.city + new Date().getTime()
            this.divisionService.create(division,{isDecrypt: true})
            --i
          }
        }
        if (i == 0) {
          resolve(null)
        }
      } else {
        resolve(null)
      }
    });
  };

  practiceFaxNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5) { phoneNumber = phoneNumber + item }
      });
    }
    return this.practice.meta.fax = phoneNumber
  }

  practicePhoneNumberFormat(mobile) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5) { phoneNumber = phoneNumber + item }
      });
    }
    return this.practice.phone = phoneNumber
  }

  divisionPhoneNumberFormat(mobile, index) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5) { phoneNumber = phoneNumber + item }
      });
    }
    return this.divisions[index].meta.phone = phoneNumber
  }

  divisionFaxNumberFormat(mobile, index) {
    if (!mobile) {
      return
    }
    let phoneNumber = mobile
    let phoneNumberArray
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '')
    if (phoneNumber) {
      phoneNumberArray = phoneNumber.split('')
      phoneNumber = ''
      phoneNumberArray.forEach((item, i) => {
        if (i < 2) { phoneNumber = phoneNumber + item }
        if (i == 2) { phoneNumber = phoneNumber + item + '-' }
        if ((i < 5) && (i > 2)) { phoneNumber = phoneNumber + item }
        if (i == 5) { phoneNumber = phoneNumber + item + '-' }
        if (i > 5) { phoneNumber = phoneNumber + item }
      });
    }
    return this.divisions[index].meta.fax = phoneNumber
  }

  getTimeZone(value) {
    value = Number(value)
    const timezones = this.timeZones;
    const selectedTimeZone = timezones.filter((time) => time.offset == value);
    if(selectedTimeZone.length) {
      return selectedTimeZone[0].timeZone;
    } else {
      return 'GMT';
    }
  }

  addCategory() {
    const dialogRef = this.dialog.open(SelectSurveyCategoryDialogComponent, {
      width: '450px',
    });
    dialogRef.componentInstance.existingCategories = this.categories
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let category = new SurveyCategory(result)
        this.categories.push(new PracticeSurveyCategory({ category: category }))
      }
    })
  }

  removeCategory(index) {
    this.categories.splice(index, 1)
  }

  saveCategories() {
    this.isProcessing = true
    this.practiceService.surveyCategory.update(this.practice.code, { categories: this.categories }).subscribe(() => {
      this.isProcessing = false
    }, err => {
      this.isProcessing = false
    })
  }

}
