import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { IApi, RoleService } from '../../core/services';
import { Role } from '../models';
import { DirectoryApi } from './directory.api';

@Injectable()
export class DirectoryRoleService extends DirectoryApi<Role> {

  searchAdmin: IApi<Role>;

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('rolesNew', http, roleService, uxService);
    this.searchAdmin = new DirectoryApi<Role>(`rolesNew/searchAdmin`, http, roleService, uxService);
  }
}
