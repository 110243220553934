import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionDecryptionService {

  private readonly IV: string = '0123456789abcdef0123456720152301';
  private readonly KEY: string = '0123456789abcdef0123456789abcdef0123456789abcdef1023214568741023';

  constructor() { }

  encrypt(text: string | number | null | undefined, encrypt: boolean = true): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        if (text === null || text === undefined) {
          return resolve(""); // Return empty if input is null, undefined
        }

        if(!encrypt) {
          return resolve(text.toString());
        }

        text = text.toString().trim();

        if (text === "") {
          return resolve(""); // Return empty if input is empty
        }

        const key = crypto.enc.Hex.parse(this.KEY);
        const iv = crypto.enc.Hex.parse(this.IV);

        const encrypted = crypto.AES.encrypt(text, key, { iv: iv }).toString(); // Encrypt with key and IV

        resolve(escape(encodeURIComponent(encrypted)));

      }
      catch (error) {
        reject("Encryption failed"); // Handle errors
      }
    });

  }

  decrypt(encryptedText: string | null | undefined, type = 'string'): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        if (encryptedText === null || encryptedText === undefined) {
          return resolve(""); // Return empty if input is null, undefined
        }

        encryptedText = encryptedText.toString().trim();

        if (encryptedText === "") {
          return resolve(""); // Return empty if input is empty
        }

        const key = crypto.enc.Hex.parse(this.KEY);
        const iv = crypto.enc.Hex.parse(this.IV);

        // Decode URL-safe characters back to their original values
        const decodedText = decodeURIComponent(unescape(encryptedText));

        // Decrypt the cipher text
        const decrypted = crypto.AES.decrypt(decodedText, key, { iv: iv });

        // Parse the decrypted data
        const decryptedStr = decrypted.toString(crypto.enc.Utf8);
        
        if (type === 'string') {
          resolve(decryptedStr);
        } else {
          try {
            resolve(JSON.parse(decryptedStr));
          } catch (parseError) {
            reject(new Error('Failed to parse decrypted JSON'));
          }
        }
      }
      catch (error) {
        reject(error); // Handle errors
      }
    });
  }
}
