import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UxService } from '~/app/core/services';
import { PracticeSurveyCategory } from '~/app/open-age/telepain/models/practice-survey-category';
import { SurveyCategory } from '~/app/open-age/telepain/models/survey-category';
import { SurveyCategoryService } from '~/app/open-age/telepain/services/survey-category.service';

@Component({
  selector: 'app-select-survey-category',
  templateUrl: './select-survey-category.component.html',
  styleUrls: ['./select-survey-category.component.css']
})
export class SelectSurveyCategoryDialogComponent implements OnInit {

  isProcessing: boolean;
  category: SurveyCategory;
  categories: SurveyCategory[];
  existingCategories: PracticeSurveyCategory[];

  constructor(
    public dialogRef: MatDialogRef<SelectSurveyCategoryDialogComponent>,
    private catgoryApi: SurveyCategoryService,
    private errorHandler: UxService,
  ) { }

  ngOnInit() {
    this.isProcessing = true
    this.catgoryApi.search({}, {isDecrypt: true}).subscribe(page => {
      this.isProcessing = false
      this.categories = []
      for (const item of page.items) {
        if (!this.checkExisting(item)) {
          this.categories.push(item)
        }
      }
      if(!this.categories.length) {
        if (!this.category) {
          this.errorHandler.handleError("No more categories available")
          this.dialogRef.close(false)
        }
      }
    })
  }

  select() {
    if (!this.category) {
      this.errorHandler.handleError("Please select category")
      return
    }
    this.dialogRef.close(this.category)
  }

  checkExisting(item: SurveyCategory): boolean {
    let exists = false
    for (const existingCategory of this.existingCategories) {
      if (existingCategory.category.id == item.id) {
        exists = true
      }
    }
    return exists
  }

}
