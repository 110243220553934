import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor() {}
  get patterns() {
    return {
      email: {
        pattern: '^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
        message: 'Enter a valid email',
      },
      phone:{
        minLength: 10,
        maxLength: 10,
        pattern: '^\d{3}-\d{3}-\d{4}$',
        message: 'Enter a valid Phone number',
      },
      fax:{
        pattern: '^\d{3}-\d{3}-\d{4}$',
        message: 'Enter a valid Fax number',
      },
      name: {
        minLength: 2,
        maxLength: 20,
        pattern: '^[a-zA-Z0-9\\-/ ]+$',
        patternMessage: 'Enter a valid user name',
        message: 'Name is required',
        minLengthMessage: 'Name must be at least 2 characters long.',
        maxLengthMessage: 'Name cannot exceed 20 characters.',
      },
      addressLine: {
        pattern: '^[a-zA-Z0-9.,\\-/# ]+$',
        message: 'Enter a valid address',
        minLength: 3,
        maxLength: 50,
        minLengthMessage: 'Address must be at least 3 characters long.',
        maxLengthMessage: 'Address cannot exceed 50 characters.',
      },
      validString :{
        minLength: 2,
        maxLength: 20,
        pattern: '^[a-zA-Z ]+$',
        message: 'Only enter letters',
        minLengthMessage: 'must be at least 2 characters long.',
        maxLengthMessage: 'cannot exceed 20 characters.',
      },
      validNumber: {
        pattern: '^[0-9]+$', 
        message: 'Only enter numbers',
      }
    };
  }
}
